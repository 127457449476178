.sms-editor-wrapper{
    overflow-y:auto;
    background-color: rgb(242, 242, 242);
}
.sms-editor-wrapper .RichEditor-root{
    min-height: 150px;
}
.sms-editor{
    min-height:350px;
  }

.emoji-select::before{
    content: "☺";
    font-size: 1.5rem;
}

.clear-button{
    font-size: 14px !important;
}